<template>
    <v-col
        cols="12"
        md="10"
    >
      <v-row
        class="mx-0 mt-1 mb-1"
        elevation="2"
      >
        <v-btn
          color="secondary"
          @click="goBack"
        >Back</v-btn>
      </v-row>
      <v-list>
          <v-list-item>
            <v-row class="text-center mx-0">
                <v-col
                  cols="5"
                >Calls</v-col>
                <v-col
                  cols="2"
                >{{$route.params.ticker}}</v-col>
                <v-col
                  cols="5"
                >Puts</v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
              <v-row class="text-center">
                  <v-col>Bid</v-col>
                  <v-col>Mid</v-col>
                  <v-col>Ask</v-col>
                  <v-col>Strike</v-col>
                  <v-col>Bid</v-col>
                  <v-col>Mid</v-col>
                  <v-col>Ask</v-col>
              </v-row>
          </v-list-item>
          <v-list-item
              v-for="(item, index) in callsChain"
              :key="item.symbol"
          >
            <v-row class="text-center">
              <v-col>{{toCurrency(item.bid)}}</v-col>
              <v-col>{{toCurrency((item.ask + item.bid) / 2)}}</v-col>
              <v-col>{{toCurrency(item.ask)}}</v-col>
              <v-col>{{toCurrency(item.strike)}}</v-col>
              <v-col>{{toCurrency(putsChain[index].bid)}}</v-col>
              <v-col>{{toCurrency((putsChain[index].ask + putsChain[index].bid) / 2)}}</v-col>
              <v-col>{{toCurrency(putsChain[index].ask)}}</v-col>
            </v-row>
          </v-list-item>
      </v-list>
    </v-col>
</template>
<script>
// File makes the assumption that calls and puts will always be even and that they come in the same order and in order
export default {
  name: 'OptionsList',
  data: () => ({
    datePicker: new Date()
  }),
  computed: {
    putsChain () {
      return this.$store.getters.stockOptionsChain.filter(option => option.option_type === 'put')
    },
    callsChain () {
      return this.$store.getters.stockOptionsChain.filter(option => option.option_type === 'call')
    }
  },
  methods: {
    dateChange () {
      this.$store.dispatch('stockOptionsChain', { ticker: this.$route.params.ticker, expiration_date: Intl.DateTimeFormat('fr-CA').format(this.getNextDayOfWeek(this.datePicker)) })
    },
    getNextDayOfWeek (date, dayOfWeek = 5) {
      var resultDate = new Date(date.getTime())
      resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7)
      return resultDate
    },
    toCurrency (number) {
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
    },
    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  mounted () {
    this.$store.dispatch('stockOptionsChain', { ticker: this.$route.params.ticker, expiration_date: Intl.DateTimeFormat('fr-CA').format(this.getNextDayOfWeek(new Date())) })
  }
}
</script>
<style>
.v-list.v-list-item{
    text-align: center;
}
</style>
